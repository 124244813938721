import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


axios.defaults.baseURL = 'https://healthlinedistributors.in/backend/index.php/'; 
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;';
//axios.defaults.headers.common['Authorization'] =(localStorage['token'])?localStorage['token']:'';
const app = createApp(App);
app.config.globalProperties.$apiname = 'https://healthlinedistributors.in/backend/index.php/';
app.config.globalProperties.$baseurl = 'https://healthlinedistributors.in/backend/';
axios.interceptors.request.use(function(config) {
   $(".loader_div").show();
    return config;
  }, function(error) {
    // Do something with request error
    console.log('Error');
    return Promise.reject(error);
  });
  
  axios.interceptors.response.use(function(response) {
    // Do something with response data
    $(".loader_div").hide();
    console.log('Done with Ajax call');
  
    return response;
  }, function(error) {
    $(".loader_div").hide();
    // Do something with response error
    console.log('Error fetching the data');
    return Promise.reject(error);
  });

app.use(VueSweetalert2);
app.use(router);
app.mount('#app');